const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.newmont.forwoodsafety.com',
    APP_CLIENT_ID: '1gvjf9ejl7uchk5e5s1ibv396t',
    USER_TOKEN_URL: 'https://8f1caoi0e8.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://4ntltfllgf.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.9.1',
    HOSTNAME: 'https://admin.newmont.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.newmont.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.newmont.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.newmont.forwoodsafety.com',
    WEBSOCKET: 'wss://60brha8aij.execute-api.us-west-2.amazonaws.com/prod'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;